require('./bootstrap');
import Cleave from 'cleave.js';

var element = document.getElementById("toggler");

document.querySelector('#menu_toggle')
    .addEventListener('click', () => {
        element.classList.toggle("d-none");
    });

document.querySelectorAll("[id^='geboortedatum']")
    .forEach((date_input) => {
        new Cleave(date_input, {
            date:        true,
            datePattern: ['d', 'm', 'Y'],
            delimiter:   '-'
        });
    });


const brand = document.querySelector(".navbar-brand");
let ticking = false;

window.addEventListener("scroll", () => {
    if (!ticking) {
        ticking = true;
        setTimeout(() => {
            ticking = false;    
        }, 30);

        const scroll = window.scrollY;

        if (scroll < 60) {
            brand.style.transform = "translateY(13px) scale(1)";
        } else {
            brand.style.transform = "scale(0.5)";
        }
    }
});
